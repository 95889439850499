import React, { useEffect, useState } from 'react';
import environments from "../utils/environments";
import { useAuth } from 'react-oidc-context';


const Listings = ({ userID, userName }) => {
    const [listings, setListings] = useState([]);
    const auth = useAuth();

    const fetchListings = () => {

        const token = auth.user?.access_token;

        fetch(environments.apiUrl+"/api/userListingData/"+userID, {
            headers: {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
            },
        })

            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setListings(data.listings);
                } else {
                    console.error('Fehler beim Laden der Listings:', data.message);
                }
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Listings:', error);
            });
    };

    const deleteListing = (listingID) => {

        const token = auth.user?.access_token;

        fetch(environments.apiUrl+"/api/userListingData/delete", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ UserID: userID, ListingID: listingID }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setListings(listings.filter(listing => listing.ListingID !== listingID));
                } else {
                    console.error('Fehler beim Löschen des Listings:', data.message);
                }
            })
            .catch(error => {
                console.error('Fehler beim Löschen des Listings:', error);
            });
    };

    useEffect(() => {
        fetchListings();
    }, []);

    return (
        <div>
            <h2>Gespeicherte Listings für {userName}</h2>
            {listings.length > 0 ? (
                <ul>
                    {listings.map(listing => (
                        <li key={listing.ListingID}>
                            <h3>{listing.PropertyType} in {listing.City}</h3>
                            <p>Preis: {listing.PurchasePrice} €</p>
                            <p>Adresse: {listing.Address}</p>
                            <p>Quadratmeter: {listing.Area} m²</p>
                            <button onClick={() => deleteListing(listing.ListingID)}>Löschen</button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Keine Listings gefunden.</p>
            )}
        </div>
    );
};

export default Listings;