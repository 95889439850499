import React, { useEffect, useState } from 'react';
import environments from "../utils/environments";
import { useAuth } from 'react-oidc-context';


const Settings = ({ userID, userName, userEmail }) => {
    const [equityRatio, setEquityRatio] = useState(0.5);
    const [interestRate, setInterestRate] = useState(0.045);
    const auth = useAuth();

    useEffect(() => {

        const token = auth.user?.access_token;
        // Einstellungen von der API holen
        fetch(environments.apiUrl+"/api/userSettings/"+userID, {
            headers: {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setEquityRatio(data.settings.EquityRatio);
                    setInterestRate(data.settings.InterestRate);
                } else {
                    console.error('Fehler beim Laden der Einstellungen:', data.message);
                }
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Einstellungen:', error);
            });
    }, [userID]); // Abhängigkeit hinzugefügt, um die Effekte bei Änderung von userID neu zu laden

    const handleSave = () => {
        const updatedSettings = {
            UserID: userID, 
            EquityRatio: equityRatio,
            InterestRate: interestRate,
            Username: userName,
            Email: userEmail,
        };

        const token = auth.user?.access_token;

        fetch(environments.apiUrl+"/api/userSettings/saveSettings", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedSettings),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert('Einstellungen erfolgreich gespeichert!');
                } else {
                    console.error('Fehler beim Speichern der Einstellungen:', data.message);
                }
            })
            .catch(error => {
                console.error('Fehler beim Speichern der Einstellungen:', error);
            });
    };

    return (
        <div>
            <h2>Benutzereinstellungen</h2>
            <div>
                <label>
                    Eigenkapitalquote (%):
                    <input
                        type="number"
                        value={equityRatio}
                        onChange={(e) => setEquityRatio(e.target.value)}
                    />
                </label>
            </div>
            <div>
                <label>
                    Zinssatz (%):
                    <input
                        type="number"
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)}
                    />
                </label>
            </div>
            <button onClick={handleSave}>Einstellungen speichern</button>
        </div>
    );
};

export default Settings;